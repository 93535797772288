<template>
  <div class="community">
    <div>
      <el-button size="small" @click="addCommunity">
        <i class="el-icon-plus"></i> 添加</el-button
      >
      <el-button size="small" @click="editCommunity">
        <i class="el-icon-setting"></i> 修改</el-button
      >
    </div>
    <hr />
    <el-table
      :data="tableData"
      border
      highlight-current-row
      @current-change="handleChange"
      :row-style="{ height: '50px' }"
      style="
         {
          width: 100%;
        }
      "
      max-height="800"
    >
      <el-table-column prop="index" label="编号" width="50"> </el-table-column>
      <el-table-column prop="title" label="标题" width="200"> </el-table-column>
      <el-table-column prop="content" label="通知内容" width="auto">
      </el-table-column>
      <el-table-column prop="create_time" label="发布时间" width="180">
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @current-change="handlePagination"
        v-model:currentPage="currentPage1"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
  <!-- 添加修改弹出通知 -->
  <el-dialog
    :title="cTitle == 'add' ? '发布通知' : '修改通知'"
    v-model="communityModel"
  >
    <el-form
      :model="form"
      label-width="100px"
      :rules="rules"
      ref="formCommunity"
    >
      <el-form-item prop="title">
        <el-input
          v-model="form.title"
          type="text"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>
      <el-form-item prop="content">
        <el-input
          v-model="form.content"
          :rows="5"
          type="textarea"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <input
          class="upload"
          type="file"
          ref="upload"
          accept="image/*"
          @change="uploadImage($event)"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          size="mini"
          v-model="form.previewText"
          placeholder="预览内容"
          :disabled="true"
          :autosize="{ minRows: 3, maxRow: 5 }"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="success" style="margin-right: 300px" @click="getPre"
          >预览</el-button
        >
        <el-button @click="communityModel = false">取 消</el-button>
        <el-button @click="send" type="primary">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref } from "vue";
import axios from "axios";

import { getCommunity, postAddCommunity, getPreview } from "../http/community";
import { ElMessage } from "element-plus";
// @ is an alias to /src
export default {
  name: "Community",
  setup() {
    const previewText = ref("");
    const cTitle = ref("");
    const tableData = reactive([]);
    const currentPage1 = ref(1);
    const totalCount = ref(0);
    const communityModel = ref(false);
    const current = reactive({
      title: "",
      content: "",
      id: "",
      img: "",
    });
    const form = reactive({
      title: "",
      content: "",
      previewText: "",
    });
    const rules = reactive({
      title: [{ required: "true", message: "标题不能为空" }],
      content: [{ required: "true", message: "内容不能为空" }],
    });
    const formCommunity = ref(null);
    //选中通知操作
    function handleChange(val) {
      current.title = val.title;
      current.content = val.content;
      current.id = val.id;
      current.img = val.img;
    }
    //翻页
    function handlePagination(val) {
      community(val);
    }

    //添加通知

    function addCommunity() {
      form.title = "";
      form.content = "";
      cTitle.value = "add";
      formdata = new FormData();

      communityModel.value = true;
    }

    //修改通知

    function editCommunity() {
      if (current.id) {
        cTitle.value = "modify";
        communityModel.value = true;
        form.title = current.title;
        form.content = current.content;
        formdata = new FormData();
        form.previewText = "";
      } else {
        ElMessage.warning("请选择一项");
      }
    }
    //上传图片
    let formdata;
    function uploadImage(e) {
      let file = e.target.files[0];
      formdata = new FormData();
      formdata.append("file", file);
    }

    //发布通知
    function send() {
      formCommunity.value.validate((valid) => {
        if (valid) {
          if (cTitle.value == "add") {
            let formd = new FormData();
            if (formdata.get("file") != null) {
              formd.append("file", formdata.get("file"));
            }
            formd.append("status", "add");
            formd.append("id", 0);
            formd.append("title", form.title);
            formd.append("content", form.content);
            let url = "https://rhkpwy.com/Notice/AddNotice";
            axios.post(url, formd).then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                communityModel.value = false;
                ElMessage.success("添加成功");
                community(1);
                current.id = "";
              } else if (state == 1) {
                ElMessage.error(data.message);
              }
            });
          } else if ((cTitle.value = "modify")) {
            let formd = new FormData();
            if (formdata.get("file") != null) {
              formd.append("file", formdata.get("file"));
            }
            formd.append("status", "modify");
            formd.append("id",  current.id);
            formd.append("title", form.title);
            formd.append("content", form.content);
            let url = "https://rhkpwy.com/Notice/AddNotice";
               axios.post(url, formd).then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                communityModel.value = false;
                ElMessage.success("添加成功");
                community(1);
                current.id = "";
              } else if (state == 1) {
                ElMessage.error(data.message);
              }
            });
          }
        }
      });
    }

    //获取初始化数据
    function community(page) {
      getCommunity(page).then((res) => {
        let data = res.data;
        let state = data.state;
        if (state == 0) {
          tableData.length = 0;
          totalCount.value = data.data.total;
          let result = data.data.rows;
          for (let i = 1; i <= result.length; i++) {
            let refData = {
              index: result[i - 1].no,
              title: result[i - 1].title,
              content: result[i - 1].content,
              create_time: result[i - 1].create_time,
              id: result[i - 1].id,
              isSend: result[i - 1].isSend,
              img: result[i - 1].img,
            };
            tableData.push(refData);
          }
        } else if (state == 1) {
          ElMessage.error("获取失败");
        }
      });
    }
    community(1);

    //预览
    function getPre() {
      getPreview(form.content).then((res) => {
        form.previewText = res.data.data;
      });
    }

    return {
      getPre,
      previewText,
      current,
      tableData,
      currentPage1,
      totalCount,
      handlePagination,
      cTitle,
      uploadImage,
      communityModel,
      editCommunity,
      addCommunity,
      form,
      rules,
      formCommunity,
      send,
      handleChange,
    };
  },
};
</script>

<style scoped>
.community {
  width: 100%;
  height: 100%;
  position: relative;
}
.el-table /deep/ td {
  color: black;
}
.block {
  position: absolute;
  bottom: 0;
}
/deep/.el-table__body tr.current-row > td {
  background-color: #ffe48d;
}
</style>
